import React from "react"

import { StaticQuery, graphql } from "gatsby"
import ArticlesComponent from "../components/articles"
import Seo from "../components/seo"
import Layout from "../components/layout"

const NewsletterPage = () => {
  return (
    <Layout>
      <StaticQuery
        query={graphql`
          query {
            allStrapiArticle {
              edges {
                node {
                  strapiId
                  title
                  description
                  url
                  createdAt
                  users {
                    id
                  }
                  category {
                    name
                  }
                  media
                  approved
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Seo title={"You are subscribed!"} />
            <section className="hero is-dark is-small">
              <div className="hero-body">
                <div className="container pb-6">
                  <div className="columns is-centered is-top">
                    <div className="column is-7 mt-6 has-text-centered">
                      <h1 className="is-size-3 has-text-weight-bold has-text-white title-font">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 50 50"
                          className="is-24 is-success top-2px mr-1"
                        >
                          <path
                            d="M25,2C12.318,2,2,12.318,2,25c0,12.683,10.318,23,23,23c12.683,0,23-10.317,23-23C48,12.318,37.683,2,25,2z M35.827,16.562 L24.316,33.525l-8.997-8.349c-0.405-0.375-0.429-1.008-0.053-1.413c0.375-0.406,1.009-0.428,1.413-0.053l7.29,6.764l10.203-15.036 c0.311-0.457,0.933-0.575,1.389-0.266C36.019,15.482,36.138,16.104,35.827,16.562z"
                            fill="#8FC238"
                          />
                        </svg>
                        You are subscribed, welcome to our community!
                      </h1>
                      <h2 className="is-size-6 has-text-white mt-4">
                        Check below our most voted resources yet
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section">
              <div className="container">
                <div className="columns">
                  <div className="column is-8">
                    <p className="has-opacity-65">BEST RESOURCES</p>
                    <ArticlesComponent
                      articles={data.allStrapiArticle.edges}
                      defaultSort="best"
                      limit="2"
                    />
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      />
    </Layout>
  )
}

export default NewsletterPage
